import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import Seo from '../components/Seo';

const Blog = ({ data, pageContext }) => {
    const { allMdx } = data;
    const { node:dataNode } = allMdx.edges[0];
    const { title, date, description } = dataNode.frontmatter;
    const { body } = dataNode;
    const { next, previous } = pageContext;

    return (
        <Layout 
            pageContext={pageContext}
        >
            <Seo title={title} description={description} />
            <div className="sidebar-container">
                <Sidebar />
                <div className="sidebar-content text-content">
                    <h1>{title}</h1>
                    <p className="date">{date}</p>
                    <MDXRenderer>
                        {body}
                    </MDXRenderer>
                    <div className="page-links">
                        { previous && <Link
                            className="btn prev"
                            to={`/blog/${previous.childMdx.slug}`}
                        >
                            Previous: { previous.childMdx.frontmatter.title }
                        </Link> }
                        { next && <Link
                            className="btn next"
                            to={`/blog/${next.childMdx.slug}`}
                        >
                            Next: { next.childMdx.frontmatter.title }
                        </Link> }
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default Blog;

export const query = graphql`
    query($id: String!) {
        allMdx(filter: {id: { eq: $id } } ) {
            edges {
                node {
                    id
                    slug
                    body
                    frontmatter {
                        title
                        date(formatString: "MMM DD, YYYY")
                        description
                    }
                }
            }
        }
    }
`;